<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="title">请选择医院</div>
      <div class="page_list">
        <div class="no-data" v-if="list.length === 0 && !loading">
          <img width="185" src="../../assets/img/no-data.png" />
          暂无数据
        </div>
        <van-list
          v-model="loading"
          loading-text=" "
          :finished="finished"
          @load="onLoad"
        >
          <transition-group>
            <div class="page_items" v-for="(item, index) in list" :key="index + '_no'" @click="jump_to(item)">
              <div class="page_img">
                <img
                  src="../../assets/img/hospital_example.jpeg"
                  alt=""
                  width="100%"
                />
              </div>
              <div class="page_text">
                <div class="page_name">{{ item.hospital_name }}</div>
                <div class="page_level">
                  <span>{{ item.level }}</span>
                  {{ item.attribute }}
                </div>
                <div class="page_address">
                  {{ item.address }}
                </div>
              </div>
            </div>
          </transition-group>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { hospital_list } from "./service";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: false,
      list: [],
      finished: false,
      base_data: {
        page: -1,
        page_size: 5,
      },
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    
  },
  destroyed() {},
  methods: {
    ...mapMutations("hospital", ["updateHospital"]),
    onLoad() {
      this.base_data.page++;
      this.get_list();
    },
    async get_list() {
      this.loading = true;
      try {
        const { data } = await hospital_list(this.base_data);
        if(!data.data.length) this.finished = true
        this.list = this.list.concat(data.data);
        if (this.list.length >= data.total_elements) {
          this.finished = true;
        }
      } finally {
        this.loading = false;
      }
    },
    jump_to(item) {
      this.updateHospital(item)
      this.$router.push({
        path: 'select-department',
        query: {
          id: item.hospital_id
        }
      })
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
  padding: 0 12px;
}

.title {
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  color: #242938;
}

.page_items {
  display: flex;
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.page_img {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
}

.page_text {
  padding-left: 12px;
  flex: 1;
}

.page_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #242938;
  margin-bottom: 4px;
  max-height: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.page_level {
  font-size: 12px;
  line-height: 17px;
  color: #565656;
  margin-bottom: 4px;

  & span {
    display: inline-block;
    width: 56px;
    height: 18px;
    background: rgba(24, 144, 255, 0.1);
    border-radius: 2px;
    font-size: 12px;
    line-height: 18px;
    color: #1890ff;
    text-align: center;
    margin-right: 8px;
  }
}

.page_address {
  font-size: 12px;
  line-height: 17px;
  color: #898995;
  max-height: 17px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.v-enter,.v-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.v-enter-active, .v-leave-active {
  transition: all 1s; 
}

.no-data {
  text-align: center;
}

</style>
