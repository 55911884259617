import api from '../../utils/api'

// 授权
export async function hospital_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         page_number: 1,
  //         page_size: 5,
  //         number_of_elements: 5,
  //         total_elements: 20,
  //         total_pages: 3,
  //         data: [
  //           {
  //             hospital_id: 1,
  //             hospital_name: '罗湖区人民医院',
  //             level: '三级甲等',
  //             attribute: '公立医院',
  //             address: '深圳市罗湖区友谊路47号',
  //           },
  //           {
  //             hospital_id: 2,
  //             hospital_name: '春风院区',
  //             level: '三级甲等',
  //             attribute: '公立医院',
  //             address: '深圳市罗湖区春风路2069',
  //           },
  //           {
  //             hospital_id: 3,
  //             hospital_name: '罗湖区春风院区春风院区春风人民医院',
  //             level: '三级甲等',
  //             attribute: '公立医院',
  //             address: '深圳市罗湖区友谊路47号深圳市罗湖区友谊路47号深圳市罗湖区友谊路47号',
  //           },
  //           {
  //             hospital_id: 1,
  //             hospital_name: '罗湖区人民医院',
  //             level: '三级甲等',
  //             attribute: '公立医院',
  //             address: '深圳市罗湖区友谊路47号',
  //           },
  //           {
  //             hospital_id: 2,
  //             hospital_name: '春风院区',
  //             level: '三级甲等',
  //             attribute: '公立医院',
  //             address: '深圳市罗湖区春风路2069',
  //           },
  //         ],
  //       }
  //     })
  //   }, 300)
  // })
  return api.get(`/api/v1/mp/register/hospitals`, {params:payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}







